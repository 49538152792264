<template>
  <div class="BlackList">
    <h4>Blocklist</h4>
    <p class="mb-3">Users that you've blocked from viewing and viewing your profile.</p>
    <section class="contentBox">
      <!-- BlackList -->
      <div class="iq-card-body p-2">
        <div class="row" v-if="blackList.length">
          <div class="col-md-12 col-lg-6 mb-3" v-for="(item, index) in blackList" :key="index">
            <div class="iq-friendlist-block pr-2">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="imgbox" @click="refresh(item.user_id)">
                    <img :src="item.avatar" alt="profile-img" class="" />
                  </div>
                  <div class="friend-info pl-3">
                    <h5 class="mb-3" style="width: 180px;overflow: hidden;text-overflow: ellipsis;"
                      :title="item.nick_name">{{item.nick_name }}</h5>
                    <p class="mb-0">{{ item.country }}/{{item.organization}}</p>
                  </div>
                </div>
                <el-button type="info" @click="closePullBlack(item)">Remove</el-button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-else class="d-flex justify-content-center mt-2">
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div> -->
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    name: "BlackList",
    components: {},
    data() {
      return {
        blackList: [],
      }
    },

    created() {
      this.getPullBlackList()
    },

    methods: {
      // 获取拉黑用户列表
      async getPullBlackList() {
        let res = await this.$http.getPullBlackList()
        if (res.status == 200) {
          this.blackList = res.data
        }
      },

      // 删除已拉黑用户
      closePullBlack(item) {
        this.$confirm('Are you sure you want to remove this post from the blocklist?', 'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(async () => {
          let res = await this.$http.closePullBlack({ blacks_user_id: [item.user_id] })
          if (res.status == 200) {
            this.getPullBlackList()
            this.$message.success(res.message)
          }
        })
      }
    },
  };
</script>
<style lang="scss" scoped>
  .BlackList {
    .contentBox {
      .title {
        font-size: 20px;
        color: #333333;
        margin-bottom: 8px;
      }
    }

    .iq-card-body {
      max-height: 600px;
      overflow-y: auto;
    }

    .imgbox {
      width: 150px !important;
      height: 150px !important;

      img {
        width: 150px;
        height: 150px;
      }
    }
  }
</style>